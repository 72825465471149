import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_CategoryMultiSelect = _resolveComponent("CategoryMultiSelect")!
  const _component_NumberFormatter = _resolveComponent("NumberFormatter")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_RapportFilter = _resolveComponent("RapportFilter")!

  return (_openBlock(), _createBlock(_component_RapportFilter, {
    startAt: _ctx.input.startAt,
    "onUpdate:startAt": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.input.startAt) = $event)),
    endAt: _ctx.input.endAt,
    "onUpdate:endAt": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.input.endAt) = $event)),
    period: _ctx.input.period,
    "onUpdate:period": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.input.period) = $event)),
    label: "none",
    loading: _ctx.loading,
    onValidate: _cache[6] || (_cache[6] = ($event: any) => (_ctx.initData()))
  }, {
    table: _withCtx(() => [
      _createVNode(_component_DataTable, {
        dataKey: "id",
        rowHover: false,
        "auto-layout": "",
        value: _ctx.expenses,
        rows: 20,
        onFilter: _ctx.onFilter,
        "rows-per-page-options": _ctx.rowsPerPageOptions,
        filters: _ctx.filters,
        "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
        filterDisplay: "menu",
        "sort-order": 0,
        responsiveLayout: "scroll",
        showGridlines: "",
        paginator: true,
        class: "p-datatable-sm",
        scrollable: "",
        "scroll-height": "flex"
      }, {
        header: _withCtx(() => [
          _createVNode(_component_Button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refresh())),
            label: _ctx.$t('clear'),
            icon: "pi pi-refresh"
          }, null, 8, ["label"]),
          _createVNode(_component_Button, {
            disabled: !_ctx.expenses.length,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.print())),
            label: _ctx.$t('pos.print'),
            icon: "pi pi-print"
          }, null, 8, ["disabled", "label"])
        ]),
        footer: _withCtx(() => []),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            "show-add-button": false,
            showFilterOperator: false,
            "filter-field": "createdAt",
            field: "createdAt",
            header: _ctx.$t('date'),
            dataType: "date",
            sortable: true,
            "sort-field": "createdAt"
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$d(data.createdAt, "long")), 1)
            ]),
            filter: _withCtx(({ filterModel }) => [
              _createVNode(_component_Calendar, {
                manualInput: false,
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                dateFormat: _ctx.$t('format.range'),
                placeholder: _ctx.$t('format.range'),
                style: {"min-width":"200px"}
              }, null, 8, ["modelValue", "onUpdate:modelValue", "dateFormat", "placeholder"])
            ]),
            filterclear: _withCtx(({ filterCallback }) => [
              _createVNode(_component_Button, {
                type: "button",
                onClick: ($event: any) => (filterCallback()),
                class: "p-button-warning p-button-sm",
                label: _ctx.$t('prime.clear')
              }, null, 8, ["onClick", "label"])
            ]),
            filterapply: _withCtx(({ filterCallback }) => [
              _createVNode(_component_Button, {
                label: _ctx.$t('search'),
                type: "button",
                icon: "pi pi-database",
                class: "p-button-sm",
                onClick: ($event: any) => (filterCallback())
              }, null, 8, ["label", "onClick"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "motif",
            "show-add-button": false,
            showFilterOperator: false,
            header: _ctx.$t('expense.motif'),
            sortable: true,
            "sort-field": "motif",
            "filter-field": "motif"
          }, {
            filter: _withCtx(({ filterModel }) => [
              _createVNode(_component_InputText, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                style: {"min-width":"200px"}
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            filterclear: _withCtx(({ filterCallback }) => [
              _createVNode(_component_Button, {
                type: "button",
                onClick: ($event: any) => (filterCallback()),
                class: "p-button-warning p-button-sm",
                label: _ctx.$t('prime.clear')
              }, null, 8, ["onClick", "label"])
            ]),
            filterapply: _withCtx(({ filterCallback }) => [
              _createVNode(_component_Button, {
                label: _ctx.$t('search'),
                type: "button",
                icon: "pi pi-database",
                class: "p-button-sm",
                onClick: ($event: any) => (filterCallback())
              }, null, 8, ["label", "onClick"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            showFilterMatchModes: false,
            "show-add-button": false,
            showFilterOperator: false,
            field: "category.label",
            sortable: true,
            "filter-field": "category.id",
            header: _ctx.$t('product.category'),
            "show-apply-button": false
          }, {
            filter: _withCtx(({ filterModel, filterCallback }) => [
              _createVNode(_component_CategoryMultiSelect, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": [($event: any) => ((filterModel.value) = $event), ($event: any) => (filterCallback())],
                items: _ctx.categories,
                style: {"max-width":"220px"}
              }, null, 8, ["modelValue", "onUpdate:modelValue", "items"])
            ]),
            filterclear: _withCtx(({ filterCallback }) => [
              _createVNode(_component_Button, {
                type: "button",
                label: _ctx.$t('prime.clear'),
                onClick: ($event: any) => (filterCallback()),
                style: {"width":"100%"}
              }, null, 8, ["label", "onClick"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "amount",
            "show-add-button": false,
            showFilterOperator: false,
            sortable: true,
            "filter-field": "amount",
            "sort-field": "amount",
            header: _ctx.$t('expense.amount'),
            dataType: "numeric"
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberFormatter, {
                value: data.amount
              }, null, 8, ["value"]),
              _createTextVNode(" " + _toDisplayString(_ctx.currency), 1)
            ]),
            filter: _withCtx(({ filterModel }) => [
              _createVNode(_component_InputNumber, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                "max-fraction-digits": 20,
                min: 0,
                mode: "decimal"
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            filterclear: _withCtx(({ filterCallback }) => [
              _createVNode(_component_Button, {
                type: "button",
                onClick: ($event: any) => (filterCallback()),
                class: "p-button-warning p-button-sm",
                label: _ctx.$t('prime.clear')
              }, null, 8, ["onClick", "label"])
            ]),
            filterapply: _withCtx(({ filterCallback }) => [
              _createVNode(_component_Button, {
                label: _ctx.$t('search'),
                type: "button",
                icon: "pi pi-search",
                class: "p-button-sm",
                onClick: ($event: any) => (filterCallback())
              }, null, 8, ["label", "onClick"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_ColumnGroup, { type: "footer" }, {
            default: _withCtx(() => [
              _createVNode(_component_Row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    footer: _ctx.$t('payment.total'),
                    colspan: 3
                  }, null, 8, ["footer"]),
                  _createVNode(_component_Column, {
                    footer: `${_ctx.formatNumber(_ctx.filteredTotal)} ${_ctx.currency}`
                  }, null, 8, ["footer"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "onFilter", "rows-per-page-options", "filters"])
    ]),
    _: 1
  }, 8, ["startAt", "endAt", "period", "loading"]))
}