import {
  ComparativeRapportInput,
  Expense,
  QueryExpensesRapportArgs,
} from "@/graphql/types";
import { gql } from "@apollo/client";
import { EXPENSE_FIELDS } from "@/graphql/expense/expense";
import { useLazyQuery } from "@vue/apollo-composable";
import { reactive, ref, computed } from "vue";
import { activeActivity } from "@/plugins/i18n";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { printRapport } from "@/components/rapport/printer";
import { useI18n } from "vue-i18n";
import { formatNumber, numberSeparators } from "@/graphql/utils/utils";

type ExpensesRapportData = {
  expensesRapport: Expense[];
};

const EXPENSES_RAPPORT = gql`
    query ExpensesRapport($input: ComparativeRapportInput!) {
        expensesRapport(input: $input) {
            ${EXPENSE_FIELDS}
        }
    }
`;
const FILTER = {
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  motif: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  "category.id": {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  amount: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

export const useExpensesRapport = () => {
  const filters = ref({ ...FILTER });
  const date = new Date();
  const { t, d, n } = useI18n();

  const input = reactive<ComparativeRapportInput>({
    activityId: activeActivity.value.id,
    startAt: moment(date).add(-1, "days").toDate(),
    endAt: date,
    period: null,
  });
  const expenses = ref<Expense[]>([]);
  const { loading, onResult, load } = useLazyQuery<
    ExpensesRapportData,
    QueryExpensesRapportArgs
  >(EXPENSES_RAPPORT, { input: { ...input } });
  onResult(({ data }) => {
    expenses.value = data.expensesRapport.map((e) => ({
      ...e,
      createdAt: new Date(e.createdAt),
    }));
  });
  function refresh() {
    Object.assign(filters.value, FILTER);
  }
  function initData() {
    void load(
      EXPENSES_RAPPORT,
      {
        input: { ...input },
      },
      { fetchPolicy: "no-cache" }
    );
  }
  const total = computed(() =>
    expenses.value.reduce((sum, cur) => sum + cur.amount, 0)
  );

  function print() {
    printRapport(`<table>
      <thead>
        <tr>
          <th class="p-text-uppercase" colspan="3">
            <h2 class="p-pt-3">${t("rapport.tab1")}</h2>
          </th>
        </tr>
        <tr>
          <th style="border-right: unset">${t("rapport.period")}</th>
          <th class="p-no-border">
            ${t("rapport.from")}
            ${d(input.startAt, "long")}
          </th>
          <th style="border-left: unset">
            ${t("rapport.to")}
            ${d(input.endAt, "long")}
          </th>
        </tr>
        <tr>
          <th class="p-text-left">${t("date")}</th>
          <th>${t("expense.motif")}</th>
          <th class="p-text-right">
            ${t("expense.amount")}
          </th>
        </tr>
      </thead>
      <tbody>
        ${expenses.value
          .map(
            (data) => `<tr>
          <td class="p-text-left">
            ${d(data.createdAt, "long")}
          </td>
          <td>${data.motif}</td>
          <td class="p-text-right">
            ${formatNumber(data.amount)}
            ${activeActivity.value.currencySymbol}
          </td>
        </tr>`
          )
          .join("")}
      </tbody>
      <tfoot>
        <tr>
          <th colspan="3">
            ${t("rapport.total")} :
             ${formatNumber(total.value)}
             ${activeActivity.value.currencySymbol}
          </th>
        </tr>
      </tfoot>
    </table>`);
  }

  const filteredTotal = ref(0);

  function onFilter(event: any) {
    filteredTotal.value = (event.filteredValue as Expense[]).reduce(
      (acc, cum) => acc + cum.amount,
      0
    );
  }

  return {
    expenses,
    loading,
    refresh,
    filters,
    initData,
    input,
    total,
    print,
    onFilter,
    filteredTotal,
    formatNumber,
  };
};
