
import { defineComponent } from "vue";
import { useExpensesRapport } from "@/graphql/rapport/expenses.rapport";
import RapportFilter from "./RapportFilter.vue";
import { activeActivity } from "@/plugins/i18n";
import { CONSTANTS } from "@/graphql/utils/utils";
import { useExpenseCategories } from "@/graphql/category/expense.categories";
import CategoryMultiSelect from "@/components/category/CategoryMultiSelect.vue";

export default defineComponent({
  name: "ExpenseRapport",
  components: { RapportFilter, CategoryMultiSelect },
  props: ["active"],
  setup() {
    return {
      ...useExpensesRapport(),
      currency: activeActivity.value.currencySymbol,
      rowsPerPageOptions: CONSTANTS.rowsPerPageOptions,
      ...useExpenseCategories(),
    };
  },
  created() {
    this.initData();
  },
});
